import { Routes } from '@angular/router';
import { OffersComponent } from './offers/offers.component';
import { CashBidsComponent } from './cash-bids/cash-bids.component';
import { FuturesComponent } from './futures/futures.component';

export const routes: Routes = [
  {
    path: 'futures',
    component: FuturesComponent,
  },
  {
    path: 'cash-bids',
    component: CashBidsComponent,
  },
  {
    path: 'offers',
    component: OffersComponent,
  },
];
