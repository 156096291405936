import { jsxs as e, jsx as n } from "react/jsx-runtime";
import { AppProvider as m, CustomizationProvider as d } from "@bushelpowered/bushel-web-offers";
import { CashBidsListPage as p } from "@bushelpowered/bushel-web-offers/pages";
import { u } from "../useFrontEndProps-D_F57BAT.js";
function C({
  onViewOffers: r,
  ...o
}) {
  const { onToast: s, toastContainer: i, ...t } = u(o), a = !o.token || o.token && t.token;
  return /* @__PURE__ */ e(m, { ...t, children: [
    /* @__PURE__ */ n(d, { onToast: s, children: a && /* @__PURE__ */ n(p, { onViewOffers: r }) }),
    i
  ] });
}
export {
  C as CashBidsFrontEnd
};
