import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import { FuturesFrontEnd } from "@bushelpowered/markets-micro-frontends/futures";
import { environment } from "../../environments/environment";

const containerRef = "futuresContainer";
@Component({
  selector: "app-futures",
  template: `<div #${containerRef}></div>`,
  styleUrls: ["./futures.component.css", "../../offers-library.css"],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class FuturesComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerRef, { static: true }) containerRef!: ElementRef;
  @Input() public accessToken = "";
  private reactRoot!: Root;
  constructor(private elm: ElementRef) {
    this.reactRoot = createRoot(elm.nativeElement);
  }
  ngOnChanges(changes: SimpleChanges) {
    this.render();
  }
  ngAfterViewInit() {
    this.render();
  }
  ngOnDestroy() {
    this.reactRoot.unmount();
  }
  private render() {
    const { accessToken } = this;

    this.reactRoot.render(
      <FuturesFrontEnd
        slug="bushelsalesdemo"
        token={accessToken}
        serviceEndpoints={environment.serviceEndpoints}
      />,
    );
  }
}
