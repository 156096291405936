<style>
  :host {
    --bright-blue: oklch(51.01% 0.274 263.83);
    --electric-violet: oklch(53.18% 0.28 296.97);
    --french-violet: oklch(47.66% 0.246 305.88);
    --vivid-pink: oklch(69.02% 0.277 332.77);
    --hot-red: oklch(61.42% 0.238 15.34);
    --orange-red: oklch(63.32% 0.24 31.68);

    --gray-900: oklch(19.37% 0.006 300.98);
    --gray-700: oklch(36.98% 0.014 302.71);
    --gray-400: oklch(70.9% 0.015 304.04);

    --red-to-pink-to-purple-vertical-gradient: linear-gradient(
      180deg,
      var(--orange-red) 0%,
      var(--vivid-pink) 50%,
      var(--electric-violet) 100%
    );

    --red-to-pink-to-purple-horizontal-gradient: linear-gradient(
      90deg,
      var(--orange-red) 0%,
      var(--vivid-pink) 50%,
      var(--electric-violet) 100%
    );

    --pill-accent: var(--bright-blue);

    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  main {
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: inherit;
    position: relative;
  }
  nav {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    nav {
      display: initial;
    }
  }
</style>

<div style="display: flex; min-height: 100vh">
  <nav style="width: 200px; background-color: lightgray">
    <ul>
      <li>
        <a
          routerLink="/futures"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >Futures</a
        >
      </li>
      <li>
        <a
          routerLink="/cash-bids"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >Cash Bids</a
        >
      </li>
      <li>
        <a
          routerLink="/offers"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          >Offers</a
        >
      </li>
    </ul>
  </nav>
  <main class="main">
    <router-outlet />
  </main>
</div>
