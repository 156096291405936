import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import * as React from "react";
import { createRoot, Root } from "react-dom/client";
import { CashBidsFrontEnd } from "@bushelpowered/markets-micro-frontends/cash-bids";
import { environment } from "../../environments/environment";

const containerRef = "cashBidsContainer";

@Component({
  selector: "app-cash-bids",
  template: `
    <div style="padding: 16px 48px;">
      Access Token
      <input [formControl]="tokenInputControl" type="text" (blur)="onBlur()" />
    </div>
    <div style="padding: 16px 48px;">
      Location IDs
      <input
        [formControl]="locationInputControl"
        type="text"
        (blur)="onBlur()"
      />
    </div>
    <div #${containerRef}></div>
  `,
  styleUrls: ["./cash-bids.component.css", "../../offers-library.css"],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [ReactiveFormsModule, CommonModule],
})
export class CashBidsComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerRef, { static: true }) containerRef!: ElementRef;
  private reactRoot!: Root;
  locationInputControl = new FormControl("");
  tokenInputControl = new FormControl("");

  ngOnChanges(changes: SimpleChanges) {
    this.render();
  }

  ngAfterViewInit() {
    this.reactRoot = createRoot(this.containerRef.nativeElement);
    this.render();
  }

  ngOnDestroy() {
    this.reactRoot.unmount();
  }

  onBlur() {
    this.render();
  }

  private render() {
    this.reactRoot.render(
      <CashBidsFrontEnd
        slug="chsdemo"
        token={
          this.tokenInputControl.value !== null
            ? this.tokenInputControl.value
            : undefined
        }
        cashBidLocationIds={
          !!this.locationInputControl.value?.length
            ? this.locationInputControl.value.split(",").map((id) => id.trim())
            : undefined
        }
        serviceEndpoints={environment.serviceEndpoints}
        featureFlags={{
          makeOffer: true,
        }}
      />,
    );
  }
}
